<template>
  <el-container style="height: 100%;" class="wrap-info">
      <el-aside width="auto" :style="{width: width+'px'}">
        <el-scrollbar> 
        <div class="wrap-mp">
          <div class="wp">
            <span>表单列表</span>
          </div>
          <el-menu :default-active="actionIndex" class="el-menu-vertical-demo">
            <el-menu-item
              :index="item.path"
              @click="nav(item)"
              v-for="item in formList"
              :key="item.path"
            >
              <el-icon>
                <Briefcase />
              </el-icon>
              <span>{{ item.label }}</span>
            </el-menu-item>
          </el-menu>
        </div>
        <div>
           <FileList ref="fileList" :width="width"></FileList>
        </div>
      </el-scrollbar>  
      </el-aside>
      <XHandle  class="myxhandle" @widthChange="widthChange" title="可拉伸"></XHandle>
      <el-main style="position: relative;">
          <router-view></router-view>
      </el-main>
  </el-container>
</template>

<script>
import { Briefcase } from "@element-plus/icons";
import XHandle from '../../components/XHandle.vue';
import FileList from "@/views/flowinfo/file.vue";

export default {
  components: {
    Briefcase,
    XHandle,
    FileList,
  },
  data() {
    return {
      formList: [],
      fid: "",
      instanceId: "", //默认查询的值
      apiUrl: this.$appConfig.apiUrls.flowApi,
      routePath: "",
      actionIndex: "",
      xmmc: "",
      queryData:[],
      width: 250,
    };
  },
  beforeRouteUpdate (to) {
    if(to.path=='/bjsx/info'){
      console.log('拦截几次');
      if(to.query.row){
        let r=JSON.parse(to.query.row)
        for(let i=0;i<this.queryData.length;i++){
          if(r.Id==this.queryData[i].instanceId){
            this.fid=this.queryData[i].fid
            this.instanceId=this.queryData[i].instanceId
          }
        }
      this.actionIndex = ''
      this.loadForms()
      }
    }
  },
  methods: {
        // 监测宽度
     widthChange(movement) {
      this.width -= movement;
      if (this.width < 250) {
        this.width = 250;
      }
      if (this.width > 600) {
        this.width = 600;
      }
    },
    nav(form) {
      const query = {
        fid: this.fid,
        instanceId: this.instanceId,
      };

      if (form.Parameters) {
        const parameters = form.Parameters.split("&");
        for (let i = 0; i < parameters.length; i++) {
          const item = parameters[i];
          const q = item.split("=");
          if (q.length === 2) {
            query[q[0]] = q[1];
          }
        }
      }
      this.$router.push({
        path: this.routePath + "/" + form.path.trim(),
        query: query,
      });
    },
    async loadForms() {
      let me = this
      await this.$ajax
        .get(
          this.apiUrl + "/api/WorkFlow/GetWorkFlowForms?workflowId=" + this.fid
        )
        .then((response) => {
          if (response.data.IsSuccess) {
            const data = response.data.Data;
            this.formList = [];

            for (let form of data ?? []) {
              this.formList.push({
                Id: form.Id,
                label: form.Title,
                path: form.Address,
                Parameters: form.Parameters,
              });
            }

            if (this.formList.length) {
              const currentForm = this.formList[0];
              this.actionIndex = currentForm.path;

              let query = {
                fid: this.fid,
                instanceId: this.instanceId,
              };
              if (currentForm.Parameters) {
                const parameters = currentForm.Parameters.split("&");
                for (let i = 0; i < parameters.length; i++) {
                  const item = parameters[i];
                  const q = item.split("=");
                  if (q.length === 2) {
                    query[q[0]] = q[1];
                  }
                }
              }
              me.queryData.push(query)
              for(let i=0;i<me.queryData.length;i++){
                for(let j=i+1;j<me.queryData.length;j++){
                   if(me.queryData[i].instanceId===me.queryData[j].instanceId){
                     me.queryData.splice(j, 1);
                      j--;
                   }
                }
              }
              this.$router.push({
                path: this.routePath + "/" + currentForm.path.trim(),
                query: query,
              });
              let rt = me.$router.routeTags
              if(me.$route.query.row){
                let rowData = JSON.parse(me.$route.query.row)
                let tagName=rowData.ZBH
                let Id=rowData.Id
                for (let r = 0; r < rt.length; r++) {
                  if (rt[r].row&&rt[r].row == me.$route.query.row) {
                    rt[r].meta.title = tagName
                    rt[r].meta.Id = Id
                  }
                }    
              }
            }
          } else {
            throw response.data.ErrorMessage;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  activated() {
    this.width = 250;
    if (this.$route.query.row) {
      const data = JSON.parse(this.$route.query.row);
      this.routePath = this.$route.path;
      this.instanceId = data.Id;
      this.xmmc = data.XMMC;
      this.fid = data.SBLX;
      this.actionIndex = "";

      this.loadForms();
    }
  },
};
</script>

<style scoped>
.wp > span {
  font-size: 18px;
  padding-left: 5px;
  line-height: 40px;
}
.wrap-mp {
  /* border: 1px solid; */
  background: #ffffff;
  /* height: 400px; */
  margin-bottom: 20px;
  border-radius: 3px;
}

.wrap-mp .el-menu {
  border-right: 0px;
}
.wrap-mp .el-menu-item.is-active {
  border-left: 3px solid #3c8dbc;
  font-weight: 800;
  color: #000000;
}
.wrap-info .el-main {
  --el-main-padding: 0 0 0 0px;
}
.wrap-info .wrap-mp ul li:last-of-type {
  border-bottom: 1px solid #f4f4f4;
}
.wrap-info {
  -moz-user-select: none; 
  -khtml-user-select: none; 
  user-select: none;
  height: 100%;
}
</style>